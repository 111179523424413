import React, { FunctionComponent } from "react";

import "./styles.scss";

const PromoBlock: FunctionComponent = props => {
  return (
    <>
      <h1>Promotional Block Experimental</h1>{" "}
    </>
  );
};

export default PromoBlock;
